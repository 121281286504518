/* LinkAction -- creates link elements for common update/destroy actions */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {
  var module = {};

  module.method = function (element, methodName) {
    var form = document.createElement('form');
    form.method = 'POST';
    form.action = element.href;
    form.style.display = 'none';
    element.parentNode.appendChild(form);
    var name = document.createElement('input');
    name.setAttribute('type', 'hidden');
    name.setAttribute('name', '_method');
    name.setAttribute('value', methodName);
    form.appendChild(name);
    var token = document.createElement('input');
    token.setAttribute('type', 'hidden');
    token.setAttribute('name', 'authenticity_token');
    token.setAttribute('value', formAuthenticityToken);
    form.appendChild(token);
    form.submit();
    return false;
  };

  module.put = function (element) {
    return this.method(element, "put");
  };

  module.delete = function (element, message) {
    if (!message) {
      message = "Are you sure you want to delete this item?";
    }
    if (confirm(message)) {
      return this.method(element, "delete");
    }
    return false;
  };

  module.archive = function (element, message) {
    if (!message) {
      message = "Are you sure you want to archive this event?";
    }
    if (confirm(message)) {
      return this.method(element, "put");
    }
    return false;
  };

  module.confirm = function (element, message) {
    if (!message) {
      message = "Are you sure?";
    }
    if (confirm(message)) {
      return this.put(element);
    }
    return false;
  };

  return module;
}));